<template>
    <el-container>
        <el-main>
            <el-row :gutter="20" justify="center" type="flex">
                <el-col>
                    <el-image src="../images/logo.png"></el-image>
                </el-col>
            </el-row>
            <el-row :gutter="20" justify="center" type="flex">
                <el-col><span>储配作业区</span></el-col>
            </el-row>
            <el-row :gutter="20" justify="center" type="flex">
                <el-col><span id="small-span">班前三问抽签系统</span></el-col>
            </el-row>
            <el-row :gutter="20" justify="center" type="flex">
                <el-col style="max-width: 960px;">
                    <router-link :key="department.DepartmentID"
                                 :to="{path:'/main',query: {department: department.DepartmentID}}"
                                 v-for="department in departments">
                        <el-button plain type="primary">{{ department.DepartmentName }}</el-button>
                    </router-link>
                </el-col>
            </el-row>
        </el-main>
        <el-footer style="height: auto;">Copyright © 2019 - 2021 程胜 版权所有</el-footer>
    </el-container>
</template>

<script>
    import {Message} from "element-ui";

    export default {
        name: "Navigate",
        data() {
            return {
                departments: null
            }
        },
        mounted() {
            const axios = require("axios");
            axios
                .get("https://qanda.andornot.cn/api/GetDepartments")
                .then(response => (this.departments = response.data.Departments))
                .catch(function (error) {
                    Message.error(error.toString());
                });
        }
    }
</script>

<style scoped>


    .el-header, .el-footer {
        background-color: #B3C0D1;
        color: #333;
        text-align: center;
        line-height: 60px;
    }

    .el-header {
        font-size: 36px;
        font-weight: bold;
    }

    .el-main {
        background-color: #E9EEF3;
        color: #333;
        text-align: center;
        line-height: 40px;
    }

    .el-button {
        width: 188px;
        margin: 6px;
        font-size: 18px;
    }

    span {
        font-size: 36px;
        font-weight: bold;
    }

    #small-span {
        font-size: 26px;
        font-weight: 400;
    }
</style>