<template>
    <el-container>
        <el-header style="height: auto;">
            <div><span>班前三问抽签系统</span></div>
            <div id="span-department">{{ department }}</div>
        </el-header>
        <el-main style="text-align:center">
            <div class="grid-content bg-purple-light bbb">
                <el-radio-group :disabled=randomStaffing||randomIssueing v-model="radio1"
                                v-on:change="changeCategory">
                    <el-radio-button label="全部"></el-radio-button>
                    <el-radio-button :key="item" :label="item" v-for="item in categories"></el-radio-button>
                </el-radio-group>
                <div>
                    <el-button id="random-staff-button" ref="randomStaff" :disabled=randomIssueing
                               :loading=randomStaffing
                               round type="primary" v-on:click="randomStaff(staffs,currentStaff)">选人
                    </el-button>
                    <el-button id="random-quiz-button" ref="randomQuiz" :disabled="randomStaffing"
                               :loading=randomIssueing
                               round type="primary" v-on:click="randomQuiz(quizzes,currentQuiz)">抽题
                    </el-button>
                </div>
            </div>
            <div class="grid-content bg-purple bbb">
                <el-tag
                        v-for="(item,index) in staffs"
                        :key="item.id"
                        :effect="index===currentStaff?'dark':'light'"
                        :type="item.excluded && index!==currentStaff?'info':''"
                        class="staff-tag">
                    {{ item.StaffName }}
                </el-tag>
            </div>
            <transition name="el-zoom-in-top">
                <div v-show=showCard class="grid-content bg-purple bbb">
                    <el-card class="box-card">
                        <div slot="header" class="clearfix">
                            <span>{{ quiz.question }}</span>
                        </div>
                        <div class="text item">
                            {{ quiz.answer }}
                        </div>
                    </el-card>
                </div>
            </transition>
            <transition name="el-zoom-in-bottom">
                <div v-show='!showCard' class="grid-content bg-purple bbb">
                    <el-tag
                            v-for="(item,index) in quizzes"
                            :key="item.id"
                            :effect="index===currentQuiz?'dark':'light'"
                            :type="item.excluded && index!==currentQuiz?'info':''"
                            class="quiz-tag">
                        {{ item.QuizID }}
                    </el-tag>
                </div>
            </transition>
        </el-main>
        <el-footer style="height: auto;">Copyright © 2019 - 2021 程胜 版权所有</el-footer>
    </el-container>
</template>

<script>
    import * as Message from "element-ui";

    let showCard = false;//题卡显示状态

    var currentStaff = -1, currentQuiz = -1;
    var Speed = 300, //初始速度
        Time, //定义对象
        cycle = 0, //转动圈数
        EndCycle = 2, //计算圈数
        flag = false, //结束转动标志
        quick = 0;

    let allQuiz = null;
    let allStaff = null;

    export default {
        name: "Main",
        data() {
            return {
                showCard: showCard,
                randomStaffing: false,
                randomIssueing: false,
                currentStaff: currentStaff,
                currentQuiz: currentQuiz,
                quiz: {},
                quizzes: allQuiz,
                staffs: allStaff,
                categories: null,
                radio1: '全部',
                department: null
            }
        },
        methods: {
            changeCategory: function (category) {
                this.currentQuiz = -1;
                this.currentStaff = -1;
                if (category === '全部') {
                    this.quizzes = allQuiz;
                    this.staffs = allStaff;
                } else {
                    this.quizzes = [];
                    for (i = 0; i < allQuiz.length; i++) {
                        if (allQuiz[i].Category === category) {
                            this.quizzes.push(allQuiz[i]);
                        }
                    }
                    this.staffs = [];
                    for (var i = 0; i < allStaff.length; i++) {
                        for (var j = 0; j < allStaff[i].Roles.length; j++) {
                            if (allStaff[i].Roles[j] === category) {
                                this.staffs.push(allStaff[i]);
                            }
                        }
                    }
                }
                this.showCard = false;
            },
            randomQuiz: function (items, index) {
                let endIndex = 0;

                //候选数量
                let count = 0;
                for (let i = 0; i < items.length; i++) {
                    if (!items[i].excluded) count++;
                }

                if (count !== 0) {
                    this.showCard = false;
                    //加减速格数
                    let startSlow;
                    if (count > 10) {
                        startSlow = 5;
                    } else {
                        startSlow = Math.ceil(count / 2);
                    }

                    let random_num;
                    const that = this;
                    if (this.randomStaffing === false && this.randomIssueing === false && count !== 0) {
                        this.randomIssueing = true;
                        do {
                            random_num = Math.floor(Math.random() * items.length);
                        }
                        while (items[random_num].excluded === true);
                        cycle = 0;
                        flag = false;

                        //确定减速格
                        endIndex = random_num;
                        for (let i = 0; i < startSlow;) {
                            if (endIndex !== 0) {
                                endIndex = endIndex - 1;
                            } else {
                                endIndex = items.length - 1;
                            }
                            if (!items[endIndex].excluded) {
                                i++;
                            }
                        }

                        if (index !== -1) {
                            index = index - 1;
                        }
                        Time = setInterval(Star, Speed);
                    }

                    // eslint-disable-next-line no-inner-declarations
                    function Star() {
                        //跑马灯变速
                        if (flag === false) {
                            //走五格开始加速
                            if (quick === startSlow) {
                                clearInterval(Time);
                                Speed = 50;
                                Time = setInterval(Star, Speed);
                            }
                            //跑N圈减速
                            if (cycle === EndCycle + 1 && index === endIndex) {
                                clearInterval(Time);
                                Speed = 300;
                                flag = true;   //触发结束
                                Time = setInterval(Star, Speed);
                            }
                        }

                        do {
                            index = index + 1;
                            if (index >= items.length) {
                                index = 0;
                                cycle++;
                            }
                        } while (items[index].excluded);

                        //结束转动并选中号码
                        if (flag === true && index === random_num) {
                            quick = 0;
                            clearInterval(Time);
                            that.randomIssueing = false;
                            items[index].excluded = true;
                            that.quiz.question = that.quizzes[index].Question;
                            that.quiz.answer = that.quizzes[index].Answer;
                            that.showCard = true;
                        }

                        that.currentQuiz = index;
                        quick++;
                    }
                } else {
                    Message.Message.warning("剩余候选题数为零");
                }
            },
            randomStaff: function (items, index) {
                let endIndex = 0;

                //候选数量
                let count = 0;
                for (let i = 0; i < items.length; i++) {
                    if (!items[i].excluded) count++;
                }

                if (count !== 0) {
                    //加减速格数
                    let startSlow;
                    if (count > 10) {
                        startSlow = 5;
                    } else {
                        startSlow = Math.ceil(count / 2);
                    }

                    let random_num;
                    const that = this;
                    if (this.randomStaffing === false && this.randomIssueing === false && count !== 0) {
                        this.randomStaffing = true;

                        let cheatCount = 0;
                        for (let i = 0; i < items.length; i++) {
                            if (items[i].cheat) cheatCount++;
                        }

                        if (cheatCount < count) {
                            do {
                                random_num = Math.floor(Math.random() * items.length);
                            }
                            while (items[random_num].excluded || items[random_num].cheat);
                        } else {
                            do {
                                random_num = Math.floor(Math.random() * items.length);
                            }
                            while (items[random_num].excluded === true);
                        }

                        cycle = 0;
                        flag = false;

                        //确定减速格
                        endIndex = random_num;
                        for (let i = 0; i < startSlow;) {
                            if (endIndex !== 0) {
                                endIndex = endIndex - 1;
                            } else {
                                endIndex = items.length - 1;
                            }
                            if (!items[endIndex].excluded) {
                                i++;
                            }
                        }

                        if (index !== -1) {
                            index = index - 1;
                        }
                        Time = setInterval(Star, Speed);
                    }

                    // eslint-disable-next-line no-inner-declarations
                    function Star() {
                        //跑马灯变速
                        if (flag === false) {
                            //走五格开始加速
                            if (quick === startSlow) {
                                clearInterval(Time);
                                Speed = 50;
                                Time = setInterval(Star, Speed);
                            }
                            //跑N圈减速
                            if (cycle === EndCycle + 1 && index === endIndex) {
                                clearInterval(Time);
                                Speed = 300;
                                flag = true;   //触发结束
                                Time = setInterval(Star, Speed);
                            }
                        }

                        do {
                            index = index + 1;
                            if (index >= items.length) {
                                index = 0;
                                cycle++;
                            }
                        } while (items[index].excluded);

                        //结束转动并选中号码
                        if (flag === true && index === random_num) {
                            quick = 0;
                            clearInterval(Time);
                            random_num = -1;
                            that.randomStaffing = false;
                            items[index].excluded = true;
                        }

                        that.currentStaff = index;
                        quick++;
                    }
                } else {
                    Message.Message.warning("剩余候选人数为零");
                }
            }
        },
        mounted() {
            const axios = require("axios");
            axios
                .get("https://qanda.andornot.cn/api/GetQuizInfo", {
                    params: {
                        department: this.$route.query.department
                    }
                })
                .then(response => {
                    if (response.data.success === true) {
                        this.department = response.data.result.Department;
                        allQuiz = response.data.result.Quiz;
                        this.quizzes = allQuiz;
                        allStaff = response.data.result.Staff;
                        this.staffs = allStaff;
                        this.categories = response.data.result.Category;
                    } else {
                        this.$router.push("/");
                    }
                })
                .catch(function (error) {
                    Message.error(error.toString());
                });
        }
    }
</script>

<style scoped>

    .el-header, .el-footer {
        background-color: #B3C0D1;
        color: #333;
        text-align: center;
        line-height: 60px;
    }

    .el-header {
        font-size: 36px;
        font-weight: bold;
    }

    .el-aside {
        background-color: #D3DCE6;
        color: #333;
        text-align: center;
        line-height: 200px;
    }

    .el-main {
        background-color: #E9EEF3;
        color: #333;
        text-align: center;
        line-height: 45px;
        padding: 10px;
    }

    #el-main-left {
        text-align: left;
    }

    #span-department {
        font-size: 20px;
        font-weight: 400;
        line-height: 40px;
    }

    .quiz-tag {
        width: 40px;
    }

    .staff-tag {
        width: 70px;
    }

    .quiz-tag, .staff-tag {
        margin: 0 2px;
        font-size: 16px;
        text-align: center;
    }

    .bbb {
        max-width: 960px;
        margin: 10px auto 0px auto;
    }

    #random-quiz-button, #random-staff-button {
        width: 100px;
    }

    body > .el-container {
        margin-bottom: 40px;
    }

    .el-container:nth-child(5) .el-aside,
    .el-container:nth-child(6) .el-aside {
        line-height: 260px;
    }

    .el-container:nth-child(7) .el-aside {
        line-height: 320px;
    }

    .el-row {
        margin-bottom: 20px;
    }

    .el-col {
        border-radius: 4px;
    }

    .bg-purple-dark {
        background: #99a9bf;
    }

    .bg-purple {
        background: #d3dce6;
    }

    .bg-purple-light {
        background: #e5e9f2;
    }

    .grid-content {
        border-radius: 4px;
        min-height: 36px;
    }

    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }

    /*    .aaa.el-tag  {
            font-size: 32px;
        }*/
    .text {
        font-size: 18px;
    }

    .item {
        margin-bottom: 18px;
    }

    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }

    .clearfix:after {
        clear: both
    }

    .box-card {
        font-size: 18px;
        text-align: left;
        line-height: 30px;
    }
</style>