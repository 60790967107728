import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
import Navigation from '../views/Navigation'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'navigation',
    component: Navigation,
    meta: {
      title: '班前三问抽签系统-储配作业区'
    }
  },
  {
    path: '/main',
    name: 'main',
    component: Main
  }
]

const router = new VueRouter({
  routes
})

export default router
